import { TicketUserComponent } from './../components/ticket-user/ticket-user.component';
import { TicketPriorityComponent } from 'app/common/components/ticket-priority/ticket-priority.component';
import { BaseService } from './../services/base.service';
import { ScrollComponent } from './../components/scroll/scroll.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldComponent } from 'app/common/components/form-field/form-field.component';
import { ErrorLabelComponent } from 'app/common/components/error-label/error-label.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicFormTemplateComponent } from '../components/dynamic-form-template/dynamic-form-template.component';
import { ErrorMessagesPipe } from '../pipes/error-messages.pipe';
import { FormsModule as ngFormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbDatepickerModule, NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTooltipModule,
  NbTreeGridModule,
  NbUserModule,
} from '@nebular/theme';
import { ScrollDirective } from '../components/scroll/scroll.directive';
import { FakeBackendProvider } from '../services/fakebackend-interceptor';
import { TicketAssigneeComponent } from '../components/ticket-assignee/ticket-assignee.component';
import { TicketStatusComponent } from '../components/ticket-status/ticket-status.component';

@NgModule({
  declarations: [
    // Commponents
    FormFieldComponent,
    ErrorLabelComponent,
    DynamicFormTemplateComponent,
    ScrollComponent,    
    TicketStatusComponent,
    TicketUserComponent,
    TicketAssigneeComponent,
    TicketPriorityComponent,
    // Pipes
    ErrorMessagesPipe,
    // Directives
    ScrollDirective,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbTreeGridModule,
    NbTooltipModule,
    NbSpinnerModule,
    ngFormsModule,
  ],
  exports: [
    // Common
    FormsModule,
    ReactiveFormsModule,
    CommonModule,

    // Theme
    NbInputModule,
    NbCardModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbCheckboxModule,
    NbRadioModule,
    NbDatepickerModule,
    NbSelectModule,
    NbIconModule,
    NbTreeGridModule,
    NbStepperModule,
    NbTooltipModule,
    NbSpinnerModule,
    ngFormsModule,

    // Components
    ErrorLabelComponent,
    ScrollComponent,
    TicketStatusComponent,
    TicketUserComponent,
    TicketAssigneeComponent,
    TicketPriorityComponent,
    FormFieldComponent,
    DynamicFormTemplateComponent,

    // Pipes
    ErrorMessagesPipe,

    // Directives
    ScrollDirective,
  ],
  providers: [
    BaseService,
    //FakeBackendProvider,
  ],
})
export class SharedModule { }
