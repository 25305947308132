import { Injectable } from '@angular/core';

@Injectable()
export class AuthService {
    KEY = 'Helpdesk';

    constructor() {}

    set(token: any) {
        localStorage.setItem(this.KEY, JSON.stringify(token));
    }

    get(): {
        access_token: string,
        expires_in: number,
        token_type: string,
        user_id: string,
        roles: string,
        tenantId: string,
        email: string,
        fullname: string,
        photo: string,
        main_role: string,
        userType: string
    } {
        try {
            const resp = JSON.parse(localStorage.getItem(this.KEY));
            return resp;
        } catch (err) {
            console.warn('Cannot parse token from storage');
            return null;
        }
    }

    valid(): boolean {
       return !!this.get();
    }

    clear() {
        delete localStorage[this.KEY];
        return true;
    }
}
