import { Component, Input, OnInit } from '@angular/core';
import { TicketService } from 'app/@core/services/ticket.service';

@Component({
  selector: 'ngx-ticket-priority',
  templateUrl: './ticket-priority.component.html',
  styleUrls: ['./ticket-priority.component.scss']
})
export class TicketPriorityComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  renderValue: string;
  priorities: any[];
  
  // constructor(private ticketService: TicketService) { }
  constructor() { }

  ngOnInit(): void {
    if(this.rowData) {
      switch(this.rowData.ticketPriority) {
        case '0a9eaa3c-10ce-11ef-a12d-bcee7b89cb8a':
          this.renderValue = 'High';
          break;
        case '02cb8f77-10ce-11ef-a12d-bcee7b89cb8a':
          this.renderValue = 'Medium';
          break;
        case 'f5d930b1-10cd-11ef-a12d-bcee7b89cb8a':
          this.renderValue = 'Low';
          break;
      }
    }
    // this.ticketService.getPriorities().subscribe(s => {
    //   this.priorities = s;
    //   if(this.rowData) {
    //     var priorityrow = this.priorities.find(x=>x.priorityId == this.rowData.ticketPriority);
    //     if(priorityrow != null){
    //       this.renderValue = priorityrow.priorityName;
    //     }
    //   }
    // });
    
    // { value: 'f5d930b1-10cd-11ef-a12d-bcee7b89cb8a', title: 'Low' },
    // { value: '02cb8f77-10ce-11ef-a12d-bcee7b89cb8a', title: 'Medium' },
    // { value: '0a9eaa3c-10ce-11ef-a12d-bcee7b89cb8a', title: 'High' },
  }

}
