import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'errorMessages',
})
export class ErrorMessagesPipe implements PipeTransform {
  transform(error: Object, label: string): Array<string> {
    if (Array.isArray(error)) {
      return error;
    }
    return this.parseErrorObject(error, label);
  }

  private parseErrorObject(error: Object, label: string): string[] {
    const errorMessages = [];
    for (const key in error) {
      if (this.knownErrors[key]) {
        errorMessages.push(this.knownErrors[key].call(this, label, error[key]));
      }
    }
    return errorMessages;
  }

  private get knownErrors(): Object {
    return {
      required: this.requiredError,
      autocomplete: this.autocompleteError,
      email: this.emailError,
      maxlength: this.maxLengthError,
      number: this.mustBeNumber,
      matDatepickerParse: this.dateTimeError,
      multipleEmail: this.emailError,
      content: this.defaultError,
    };
  }

  private defaultError(label: string): string {
    return this.errorMessage(label, 'is invalid.');
  }

  private dateTimeError(label: string): string {
    return this.errorMessage(label, 'is invalid.');
  }

  private requiredError(label: string): string {
    return this.errorMessage(label, 'is required.');
  }

  private emailError(label: string): string {
    return this.errorMessage(label, 'is invalid.');
  }

  private maxLengthError(label: string, error: any): string {
    return this.errorMessage(
      label,
      `exceed ${error.requiredLength} characters.`,
    );
  }

  private mustBeNumber(label: string): string {
    return this.errorMessage(label, `must be a number.`);
  }

  private autocompleteError(label: string): string {
    return this.errorMessage(label, `is invalid.`);
  }

  private errorMessage(label: string, message: string): string {
    return `${label || 'This field '} ${message}`;
  }
}
