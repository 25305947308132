import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {FormControl, AbstractControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {ReplaySubject, Subject} from 'rxjs';
import { Meta } from 'app/common/models/meta-data';

// All input types
export enum InputType {
  TEXTAREA = 'textarea',
  RADIOSELECT = 'radio-select',
  SELECT = 'select', // dropdown
  MULTIPLESELECT = 'multiple-select', // checkbox
  DATE = 'date',
  AUTOCOMPLETE = 'autocomplete',
  CONTENTEDITABLE = 'contenteditable',
  TEXT = 'text', // default
}

@Component({
  selector: 'ngx-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() placeholder: string;
  @Input() description: string;
  @Input() type = 'text';
  @Input() control: FormControl;
  @Input() dataText = 'name';
  @Input() dataValue = 'id';
  @Input() isShowLabel = true;
  @Input() minRow = 4;
  @Input() controlClass = '';
  @Input() textIcon = '';
  @Input() minValue = '';
  @Input() textIconTitle = '';
  @Input() textIconClass = '';
  @Input() allowFilter = false;
  @Input() maxTruncation = 2;
  @Input() canAddNewOption = false;
  private _options: Meta[] = [];
  get options(): Meta[] {
    return this._options;
  }
  @Input()
  set options(val: Meta[]) {
    this._options = val;
    if (val && val.length > 0) {
      this.filteredOptions.next(val.slice());
    }
  }

  public InputType: typeof InputType = InputType;
  public filterCtrl: FormControl = new FormControl();
  public filteredOptions: ReplaySubject<Meta[]> = new ReplaySubject<Meta[]>(1);
  protected _onDestroy = new Subject<void>();
  public newOption: string;

  @Output() onTextIconClick = new EventEmitter();
  @Output() onChanged = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (this.allowFilter) {
      this.filterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.doFilter(this.filterCtrl);
        });
    }

    if (this.type === InputType.AUTOCOMPLETE) {
      this.control.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.doFilter(this.control);
        });
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public compareWithFunc(optionValue, selectedValue): boolean {
    return optionValue == selectedValue;
  }

  public selectionChange(): void {
    const selectedOption = this._options.find(
      (x) => x[this.dataValue] === this.control.value,
    );
    this.onChanged.emit(selectedOption);
  }

  public textIconClick(): void {
    this.onTextIconClick.emit();
  }

  public get isRequired(): boolean {
    if (this.control && this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  public get isVisibleClearDate(): boolean {
    return (
      !this.isRequired &&
      this.control &&
      !!this.control.value &&
      !this.control.disabled
    );
  }

  resetInputDate(event: Event): void {
    event.stopPropagation();
    this.control.reset();
  }

  selectAll() {
    this.control.patchValue(
      this._options.map((option) => option[this.dataValue]),
    );
  }

  deselectAll() {
    this.control.patchValue([]);
  }

  getDisplayOptionsSelectedTemplate() {
    let templateHTML = '';
    const keysSelected: (number | string)[] = this.control.value;

    if (!!keysSelected) {
      templateHTML += this.getDisplayOptionsSelected(keysSelected);

      if (keysSelected.length > this.maxTruncation) {
        templateHTML += `<span>
                            (+ ${keysSelected.length - this.maxTruncation}
                               ${
                                 keysSelected.length === this.maxTruncation + 1
                                   ? 'other'
                                   : 'others'
                               })
                         </span>`;
      }
    }
    return templateHTML;
  }

  getDisplayOptionsSelected(keysSelected: (number | string)[]) {
    const memberSelected = this._options
      .filter(
        (option) =>
          keysSelected &&
          keysSelected.find((key) => key === option[this.dataValue]),
      )
      .map((option) => option[this.dataText]);
    return memberSelected.slice(0, this.maxTruncation).join(', ');
  }

  protected doFilter(control) {
    if (!this._options) {
      return;
    }
    let search = control.value;

    if (!search) {
      this.filteredOptions.next(this._options.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredOptions.next(
      this._options.filter(
        (option) => option[this.dataText].toLowerCase().indexOf(search) > -1,
      ),
    );
  }

  public addNewOption(): void {
    if (!!this.newOption) {
      this.options.unshift(
        new Meta(this.newOption, this.newOption, false, true),
      );
      this.newOption = null;
    }
  }
}
