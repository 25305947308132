
 <!-- <div class="backgroundimagebox" [ngStyle]="{backgroundImage:'url(./assets/images/cardbody.png)'}" >     -->
  <nb-layout windowMode>
    <nb-layout-column  class="backgroundimagebox" [ngStyle]="{backgroundImage:'url(./assets/images/cardbody.png)'}">
      <ng-content select="router-outlet"></ng-content>  
  
      <nb-card [nbSpinner]="loading" nbSpinnerSize="medium" nbSpinnerStatus="primary">
        <nb-card-header>
  
        </nb-card-header>
        <nb-card-body>
          <div class="row">
            <div class="col-5">
              <div class="text-center" style="padding-bottom: 15px;">
                <img class="clientlogo" src="{{logofile}}">
              </div>
              <div class="text-center" style="font-size:25px; line-height: 1.2; color: black;">
                <span>{{company_name}} </span>
              </div>
              <div class="text-center" style="font-size:14px; line-height: 1.2; color: black;">
                <span>{{company_details}} </span>
              </div>
            </div>
            <div class="col-2">
            </div>
              
            <div class="col-5">
              <form (ngSubmit)="login()" [formGroup]="form" aria-labelledby="title">
                <div class="text-center">
                  <h6>HelpDesk Login</h6>
                </div>
                <div class="form-control-group" >
                  <label class="label" for="input-email">User ID:</label><br>
                  <input nbInput formControlName="email" fullWidth name="userid" id="input-userid" placeholder="User ID"
                    fieldSize="medium" style="width: 300px;">
                  <span class="error" *ngIf="submitted && form.get('email').errors?.required">
                    Email is required.
                  </span>
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-password">Password:</label>
                  <input nbInput formControlName="password" fullWidth name="password" type="password" id="input-password"
                    placeholder="Password" fieldSize="medium" style="width: 300px;">
                  <span class="error" *ngIf="submitted && form.get('password').errors?.required">
                    Password is required.
                  </span>
                  <div class="col-12 text-right">
                  <span class="label-with-link" style="float:right;">
                    <a class="forgot-password caption-2 "
                      routerLink="../reset-password">Forgot Password?</a>
                  </span>
                  </div>
                </div>
                <br>
                <div class="text-center">
                  <button nbButton status="primary" type="submit" size="medium" style="width: 150px;" >Log In</button> 
                </div>
                <div class="col-12">
                  <label class="label" style="color: white;">V0.1.4</label>
                </div>
              </form>
              <br>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-layout-column>
  </nb-layout>
 <!-- </div> -->

