<ngx-scroll>
    <ng-container *ngIf="controlProperties.length > 0">
        <ngx-form-field *ngFor="let field of controlProperties; let i = index"
        [control]="getFormControl(field.key)"
        [dataValue]="'code'"
        [dataText]="'name'"
        [label]="field.title"
        [description]="field.description"
        [type]="field.type"
        [options]="field.values"
        [allowFilter]="field.values?.length > 5"
        [maxTruncation]="4"
        [canAddNewOption]="!isChildTab"></ngx-form-field>
    </ng-container>
</ngx-scroll>