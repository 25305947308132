import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class ProjectService {
    private path = 'project'

    constructor(private http: HttpClient) {}

    create(model: any): Observable<any> {
        return this.http.post(`${this.path}`, model);
    }

    edit(projectId: string, model: any): Observable<any> {
        return this.http.put(`${this.path}/${projectId}`, model);
    }

    delete(id: string): Observable<any> {
        return this.http.delete(`${this.path}/` + id);
    }

    get(id: string): Observable<any> {
        return this.http.get(`${this.path}/${id}`);
    }

    getProjects(): Observable<any> {
        return this.http.get(`${this.path}/getbytenant`);
    }
    getByCategory(categoryId: string): Observable<any> {
        return this.http.get(`${this.path}/getbycategory/` + categoryId);
    }
    getByCategoryNUser(userId: string, categoryId: string): Observable<any> {
        return this.http.get(`${this.path}/getbycategorynuser/${userId}/${categoryId}`);
    }
    getByCategoryNUserContract(userId: string, categoryId: string, contractId: string): Observable<any> {
        return this.http.get(`${this.path}/getByCategoryNUserContract/${userId}/${categoryId}/${contractId}`);
    }
}
