import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-contract-maintenance-period',
  templateUrl: './contract-maintenance-period.component.html',
  styleUrls: ['./contract-maintenance-period.component.scss']
})
export class ContractMaintenancePeriodComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  renderValue: string;

  constructor() { }

  ngOnInit(): void {
    if(this.rowData) {
      switch(this.rowData.contractMonthsPerMaintenancePeriod) {
        case 1:
          this.renderValue = 'Monthly';
          break;
        case 3:
          this.renderValue = 'Quaterly';
          break;
        case 6:
          this.renderValue = 'Half Yearly';
          break;
        case 12:
          this.renderValue = 'Yearly';
          break;
      }
    }
  }

}
