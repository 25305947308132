import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class UserSerivce {
    private path = 'user'

    constructor(private http: HttpClient) {}

    login(model: { email: string; password: string }) {
        const credentials = `grant_type=password&email=${model.email}&password=${model.password}`;
        const headers = new HttpHeaders().set(
            'Content-Type',
            'application/x-www-form-urlencoded',
        );
        return this.http.post(`${this.path}/login`, credentials, {
            headers: headers,
        });
    }

    resetPassword(model: any) {
        return this.http.post(`${this.path}/resetPassword`, model);
    }

    create(model: any) {
        return this.http.post(`${this.path}`, model);
    }

    list(): Observable<any> {
        return this.http.get(`${this.path}/getusers`)
    }
    getusertickercreator(userId: string): Observable<any> {
        return this.http.get(`${this.path}/getusertickercreator/${userId}`);
    }
    list_custsv(userId: string): Observable<any> {
        return this.http.get(`${this.path}/getcust_sv/${userId}`)
    }

    list_sv_notme(userId: string): Observable<any> {
        return this.http.get(`${this.path}/getusers_sv_notme/${userId}`)
    }

    types(): Observable<any> {
        return this.http.get(`${this.path}/types`)
    }

    activedeactive(userId: string) {
        return this.http.post(`${this.path}/activedeactive/${userId}`, null);
    }

    update(id: string, data: any) {
        return this.http.put(`${this.path}/${id}`, data);
    }

    updateticketcreator(userId:string, data:any, newuserdata:any){
        var newid = newuserdata.reassign_user;
        return this.http.post(`${this.path}/updateticketcreator/${userId}/${newid}`, data);
    }

    notifRegistration(id: string, connId: string) {
        return this.http.post(
            `${this.path}/register-notification?uid=${id}&connId=${connId}`,
            null,
        );
    }

    get(userId: string): Observable<any> {
        return this.http.get(`${this.path}/` + userId);
    }
    getByProject(projectId: string): Observable<any> {
        return this.http.get(`${this.path}/getusers/` + projectId);
    }

    roles(): Observable<any> {
        return this.http.get(`${this.path}/roles`);
    }
    inviteUser(model: any) {
        return this.http.post(`${this.path}/invite`, model);
    }
    uninviteUser(model: any) {
        return this.http.post(`${this.path}/uninvite`, model);
    }

    changePassword(model: any) {
        return this.http.post(`${this.path}/changePassword`, model);
    }
}
