<div class="form-group">
    <div class="label-input"
         *ngIf="label">
      <label *ngIf="isShowLabel" class="label" [ngClass]="{'label-input__title': !!description}">
        {{ label }}
        <span class="text-danger" [ngClass]="{ 'd-none': !isRequired }"> * </span>
      </label>
      <span *ngIf="!!description"
            class="label-input__description">
        {{ description }}
      </span>
    </div>
    <ng-container [ngSwitch]="type">
        <!-- TEXT AREA -->
        <ng-container *ngSwitchCase="InputType.TEXTAREA">
          <div class="py-2 height-auto">
            <textarea [ngClass]="controlClass"
                      nbInput
                      cdkTextareaAutosize
                      fullWidth
                      [cdkAutosizeMinRows]="minRow"
                      [formControl]="control"
                      style="overflow: hidden;"></textarea>
          </div>
        </ng-container>
  
        <!-- RADIO BUTTON -->
        <ng-container *ngSwitchCase="InputType.RADIOSELECT">
          <div class="checkbox-radio">
            <div class="demo-items">
              <nb-radio-group [ngClass]="controlClass" [formControl]="control">
                <nb-radio *ngFor="let option of filteredOptions | async" [value]="option[dataValue]">
                  {{ option[dataText] }}
                </nb-radio>
              </nb-radio-group>
            </div>
          </div>
        </ng-container>

        <!-- DROPDOWN LIST -->
        <ng-container *ngSwitchCase="InputType.SELECT">
            <nb-select fullWidth [ngClass]="controlClass"
                        (selectionChange)="selectionChange()"
                        [formControl]="control"
                        [compareWith]="compareWithFunc">
              <nb-option *ngFor="let option of filteredOptions | async"
                          [value]="option[dataValue]"
                          [ngClass]="{ invalid: !option.valid && !!placeholder }">
                {{ option[dataText] }}
              </nb-option>
            </nb-select>
            <mat-label *ngIf="!!placeholder">{{ placeholder }}</mat-label>
        </ng-container>
  
        <!-- DATE PICKER -->
        <ng-container *ngSwitchCase="InputType.DATE">
          <div class="picker-group">
            <input [ngClass]="controlClass"
                   nbInput
                   fullWidth
                   [min]="minValue"
                   [nbDatepicker]="datePicker"
                   [formControl]="control" />
            <i *ngIf="isVisibleClearDate"
               class="material-icons icon--close-date"
               (click)="resetInputDate($event)">close</i>
            <i class="icon icon--date-picker"></i>
          </div>
          <nb-datepicker #datePicker></nb-datepicker>
        </ng-container>

        <!-- TEXT -->
        <ng-container *ngSwitchDefault>
          <div class="text-input-wrapper"
               #inputWrapper>
            <input [ngClass]="{ 'pr-4': !!textIcon }"
                   nbInput
                   fullWidth
                   class="{{ controlClass }}"
                   [formControl]="control"
                   autocomplete="off" />
          </div>
        </ng-container>
      </ng-container>
    <ngx-error-label [control]="control"
                     [label]="label"></ngx-error-label>
  </div>
  