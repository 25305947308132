import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { UserSerivce } from 'app/@core/services/user.service';
import { ToastrComponent } from 'app/pages/modal-overlays/toastr/toastr.component';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  isShow = false;
  constructor(
    private userService: UserSerivce,
    private fb: FormBuilder,
    private toastr: NbToastrService
  ) { 
    this.form = fb.group({
      email: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  resetPassword() {
    this.submitted = true;
    if(this.form.valid) {
      this.loading = true;
      this.userService.resetPassword(this.form.value).subscribe(res => {
        this.isShow = true;
        this.loading = false;
      },
      error => {
        this.toastr.danger(error.error, 'Error');
        this.isShow = false;
        this.loading = false;
      });
    }
  }

}
