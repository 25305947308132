import { ValidationErrors, AbstractControl } from '@angular/forms';

export class CustomValidators {
  private static regexEmail = /^[\w!#$%&'*+-/=?^_`{|}~()]+@[\w!#$%&'*+-/=?^_`{|}~()]+(\.[\w!#$%&'*+-/=?^_`{|}~()]+)+$/;

  static regex(regex: string): ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const content = control.value as string;
      if (!!content && !new RegExp(regex.replace(/\//g, '')).test(content)) {
        return { content: true };
      }
      return null;
    };
  }

  static email(control: AbstractControl): ValidationErrors | null {
    const email = control.value as string;
    if (!!email && !CustomValidators.regexEmail.test(email)) {
      return { email: true };
    }
    return null;
  }

  static number(control: AbstractControl): ValidationErrors | null {
    const value = control.value as string;
    if (!!value && !/^\d+(\.\d+)?$/.test(value)) {
      return { number: true };
    }
    return null;
  }

  static autocomplete(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value === 'string' && control.dirty && !!control.value) {
      return { autocomplete: true };
    }
    return null;
  }

  static multipleEmail(control: AbstractControl): ValidationErrors | null {
    const email = control.value as string;
    if (email) {
      const arrEmails = email
        .split(';')
        .map((e) => e.trim())
        .filter((e) => !!e);
      if (arrEmails.some((e) => !CustomValidators.regexEmail.test(e))) {
        return { multipleEmail: true };
      }
    }
    return null;
  }
}
