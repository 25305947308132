import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, from, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { LoadingIndicatorService } from './loading';

@Injectable()
export class EndpointInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
        private router: Router,
        private loadingIndicatorService: LoadingIndicatorService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        const user = this.authService.get();
        if (user) {
            req = req.clone({
                setHeaders: {
                    Authorization: user.token_type + ' ' + user.access_token
                }
            });
        }
        const baseReq = req.clone({
            url: environment.endpoint + req.url,
        });
        this.loadingIndicatorService.onRequestStarted();
        return next.handle(baseReq).pipe(
            catchError((err, caught) => {
                if(err.status === 401) {
                    this.handleAuthError(baseReq.url);
                    return of(err);
                }
                throw err;
            }),
            finalize(() => {
                this.loadingIndicatorService.onRequestFinished();
            })
        );
    }

    private handleAuthError(url: string) {
        delete localStorage['Helpdesk'];
        this.router.navigate(['/login'], { queryParams: { returnUrl: url }});
    }
}
