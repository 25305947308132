import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-ticket-user',
  templateUrl: './ticket-user.component.html',
  styleUrls: ['./ticket-user.component.scss']
})
export class TicketUserComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  name: string;
  constructor() { }

  ngOnInit(): void {
    if(this.rowData) {
      this.name = this.rowData.ticketCreator.firstName + ' ' + this.rowData.ticketCreator.lastName;
    }
  }

}
