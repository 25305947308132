import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DefaultFilter } from 'ng2-smart-table';

@Component({
  //selector: 'ngx-date-filter',
  template: `
    <input nbInput placeholder="{{column.title}}" [formControl]="inputControl" [nbDatepicker]="datepicker" >
    <nb-datepicker #datepicker></nb-datepicker>
  `,
  styles: [
  ]
})
export class DateFilterSingleComponent extends DefaultFilter implements OnInit, OnChanges {
  inputControl = new FormControl();

  constructor() { 
    super();
  }

  ngOnInit() {
    this.inputControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      debounceTime(this.delay),
    )
    .subscribe((value: string) => {
      if(this.inputControl.status === 'VALID')
      {
        this.query = value !== null ? JSON.stringify(this.inputControl.value) : "";
        this.setFilter();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.query) {
      this.query = changes.query.currentValue;
      this.inputControl.setValue(this.inputControl.value);
    }
  }
}

