<span style="color:#36f;"  *ngIf="rowData.ticketStatus === 1 || rowData.ticketStatus === 2">
    {{ renderValue | uppercase }}
</span>
<span style="color:#0095ff;"  *ngIf="rowData.ticketStatus === 3">
    {{ renderValue | uppercase }}
</span>
<span style="color:#ff3d71;"  *ngIf="rowData.ticketStatus === 4">
    {{ renderValue | uppercase }}
</span>
<span style="color:#fa0;"  *ngIf="rowData.ticketStatus === 5">
    {{ renderValue | uppercase }}
</span>
<span style="color:#00d68f;" *ngIf="rowData.ticketStatus === 6 || rowData.ticketStatus === 7 || rowData.ticketStatus === 8">
    {{ renderValue | uppercase }}
</span>

<!--
    <button nbButton ghost status="primary" *ngIf="rowData.ticketStatus === 1 || rowData.ticketStatus === 2">
    {{ renderValue | uppercase }}
</button>
<button nbButton ghost status="info" *ngIf="rowData.ticketStatus === 3">
    {{ renderValue | uppercase }}
</button>
<button nbButton ghost status="danger" *ngIf="rowData.ticketStatus === 4">
    {{ renderValue | uppercase }}
</button>
<button nbButton ghost status="warning" *ngIf="rowData.ticketStatus === 5">
    {{ renderValue | uppercase }}
</button>
<button nbButton ghost status="success" *ngIf="rowData.ticketStatus === 6 || rowData.ticketStatus === 7 || rowData.ticketStatus === 8">
    {{ renderValue | uppercase }}
</button>

-->