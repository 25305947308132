import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ContractService {
  private path = 'contract';

  constructor(private http: HttpClient) {}

  getSlaContractYearMonth(contractId: string): Observable<any>{
    return this.http.get(`${this.path}/getSlaContractYearMonth/` + contractId);
  }

  getProductByContract(contractId: string): Observable<any>{
    return this.http.get(`${this.path}/getProductByContract/` + contractId);
  }

  get(contractId: string): Observable<any> {
      return this.http.get(`${this.path}/${contractId}`);
  }

  edit(contractId: string, model: any): Observable<any> {
    return this.http.put(`${this.path}/${contractId}`, model);
  }
  assignProduct(contractId: string, model: any): Observable<any> {
    return this.http.put(`${this.path}/assignProduct/${contractId}`, model);
  }
  unassignProduct(contractId: string, model: any): Observable<any> {
    return this.http.put(`${this.path}/unassignProduct/${contractId}`, model);
  }
  
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.path}/` + id);
  }

  create(model: any): Observable<any> {
    return this.http.post(`${this.path}`, model);
  }

  getContractFile(id: string): Observable<any> {
    return this.http.get(`${this.path}/getContractFile/` + id);
  }

  getContracts(): Observable<any> {
    return this.http.get(`${this.path}/getContracts`);
  }
  getSLAPreview(model: any): Observable<any> {
    const postForm = {
      contractId: model.contractSelection,
      year: model.slaYear,
      month: model.slaMonth,
      includeUserGenerated : model.includeUserGenerated == ''? false: model.includeUserGenerated,
    }
    return this.http.post(`${this.path}/getSLAPreview`, postForm);

    }

  getSLAReport(model: any): Observable<any> {
    const postForm = {
      contractId: model.contractSelection,
      year: model.slaYear,
      month: model.slaMonth,
      includeUserGenerated : model.includeUserGenerated == ''? false: model.includeUserGenerated,
    }

    return this.http.request(new HttpRequest(
      'POST',
      `${this.path}/getSLAReport`,
      postForm,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
    }
      /*
  getSLAReport(model: any, generateExcel:boolean): Observable<HttpEvent<Blob>> {
    const postForm = {
      contractId: '08d9a8d7-5fe3-414a-8bdc-6c5b32defda6',
      year: 2018,
      month: 12,
      generateExcel: true,
    }

    return this.http.request(new HttpRequest(
      'POST',
      `${this.path}/getSLAReport`,
      postForm,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
*/
}
