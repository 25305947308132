import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ngx-error-label',
  templateUrl: './error-label.component.html',
  styleUrls: ['./error-label.component.scss'],
})
export class ErrorLabelComponent implements OnInit {
  @Input() control: FormControl;
  @Input() label: string;
  constructor() { }

  ngOnInit(): void {
  }

}
