import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-ticket-assignee',
  templateUrl: './ticket-assignee.component.html',
  styleUrls: ['./ticket-assignee.component.scss']
})
export class TicketAssigneeComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  name: string;
  constructor() { }

  ngOnInit(): void {
    if(this.rowData) {
      this.name = this.rowData.ticketAssignee ? this.rowData.ticketAssignee.firstName + ' ' + this.rowData.ticketAssignee.lastName : 'Unassigned';
    }
  }

}
