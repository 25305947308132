import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class CategoryService {
    private path = 'category'

    constructor(private http: HttpClient) {}

    create(model: any): Observable<any> {
        return this.http.post(`${this.path}`, model);
    }

    edit(cateId: string, model: any): Observable<any> {
        return this.http.put(`${this.path}/` + cateId, model);
    }

    delete(id: string): Observable<any> {
        return this.http.delete(`${this.path}/` + id);
    }

    get(cateId: string): Observable<any> {
        return this.http.get(`${this.path}/${cateId}`);
    }

    getCategoriesProduct(): Observable<any> {
        return this.http.get(`${this.path}/list`)
    }

    getCategoriesTicket(): Observable<any> {
        return this.http.get(`${this.path}/listsevlevel`)
    }

    getPriority(): Observable<any> {
        return this.http.get(`${this.path}/listpriority`)
    }

    getByType(type: string): Observable<any> {
        return this.http.get(`${this.path}/getbytype/${type}`)
    }

    getByUser(type: string): Observable<any> {
        return this.http.get(`${this.path}/user/get/${type}`)
    }

    getByUserContract(type: string, contract:string): Observable<any> {
        return this.http.get(`${this.path}/user/get/${type}/${contract}`)
    }

    getPrioritybyCategory(cateId: string): Observable<any> {
        return this.http.get(`${this.path}/listprioritybycat/${cateId}`);
    }

    assignPriority(model: any): Observable<any> {
        return this.http.post(`${this.path}/assingnPriority`, model);
    }
}