import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DefaultFilter } from 'ng2-smart-table';

@Component({
  //selector: 'ngx-date-filter',
  template: `
    <input nbInput placeholder="{{column.title}}" [formControl]="inputControl" [nbDatepicker]="rangepicker">
    <nb-rangepicker #rangepicker></nb-rangepicker>
  `,
  styles: [
  ]
})
export class DateFilterComponent extends DefaultFilter implements OnInit, OnChanges {
  inputControl = new FormControl();

  constructor() { 
    super();
  }

  ngOnInit() {
    this.inputControl.valueChanges
    .pipe(
      distinctUntilChanged(),
      debounceTime(this.delay),
    )
    .subscribe((value: string) => {
      if(this.inputControl.status === 'VALID')
      {
        var dateinput = this.inputControl.value;
        dateinput.start = new Date(Date.UTC(this.inputControl.value.start.getFullYear(), this.inputControl.value.start.getMonth(), this.inputControl.value.start.getDate(), this.inputControl.value.start.getHours(), this.inputControl.value.start.getMinutes(), this.inputControl.value.start.getSeconds()));
        dateinput.end = new Date(Date.UTC(this.inputControl.value.end.getFullYear(), this.inputControl.value.end.getMonth(), this.inputControl.value.end.getDate(), this.inputControl.value.end.getHours()+23, this.inputControl.value.end.getMinutes()+59, this.inputControl.value.end.getSeconds()+59));
        this.query = value !== null ? JSON.stringify(dateinput) : "";
        this.setFilter();
      }
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.query && changes.query.currentValue != '') {
      console.log(this.query);
      this.query = changes.query.currentValue;
      const range = JSON.parse(this.query);
      const start = new Date(range.start.slice(0,-1));
      const end = new Date(range.end.slice(0,-1)) ;
      this.inputControl.value.start = start;
      this.inputControl.value.end = end;
      this.inputControl.setValue(this.inputControl.value);
    }
  }
}
