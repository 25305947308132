import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PerkakasanService {
    private path = 'ticketperkakasan';

    constructor(private http: HttpClient) {}

    getperkakasanlist(ticketguid: string): Observable<any>{
        return this.http.get(`${this.path}/${ticketguid}`);
    }

    createperkakasan(model: any, ticketguid: string): Observable<any> {
        return this.http.post(`${this.path}/${ticketguid}`, model);
    }

    edit(perkakasan: any, model: any): Observable<any> {
        return this.http.post(`${this.path}/edit/${perkakasan.ticketPerkakasanId}`, model);
    }

    delete(perkakasan: any): Observable<any> {
        return this.http.post(`${this.path}/delete`, perkakasan);
    }
}