import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { AuthService } from 'app/@core/services/auth.service';
import { UserSerivce } from 'app/@core/services/user.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  returnUrl: string;
  company_name:string = "";
  company_details: string = "";
  loading = false;
  logofile = "";
  constructor(
    protected router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private userService: UserSerivce,
    private toastCtrl: NbToastrService,
    private authService: AuthService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
  ) {
    this.form = fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    })
    this.logofile = environment.logo;
    this.company_name = environment.companyname;
    this.company_details = environment.companydetails;
  }

  ngOnInit(): void {
    this._document.getElementById('appFavicon').setAttribute('href', environment.icon);
    this._document.getElementById('appFaviconPNG').setAttribute('href', environment.iconpng);
    //this._document.getElementById('mainlogo').setAttribute('src', environment.logo);
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  login() {
    this.submitted = true;
    const model = {
      email: this.form.get('email').value,
      password: this.form.get('password').value
    }
    if(this.form.valid) {
      this.loading = true;
      this.userService.login(model).subscribe(res => {
        this.authService.set(res);
        this.router.navigateByUrl(this.returnUrl);
        this.loading = false;
      }, err => {
        if (err.status && err.status === 400) {
          this.toastCtrl.danger(err.error, 'Error');
        } else {
          this.toastCtrl.danger(err.message, 'Error');//this.toastCtrl.danger(err || err.message, 'Error');
        }
        this.loading = false;
      })
    }
  }

  getConfigValue(key: string) {

  }
}
