import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent implements OnInit {
  @Input() value: string | number;
  @Input() rowData: any;
  renderValue: string;
  
  constructor() { }

  ngOnInit(): void {
    if(this.rowData) {
      switch(this.rowData.ticketStatus) {
        case 1:
          this.renderValue = 'Waiting For Support';
          break;
        case 2:
          this.renderValue = 'Waiting For Customer';
          break;
        case 3:
          this.renderValue = 'In Progress';
          break;
        case 4:
          this.renderValue = 'ESCALATED';
          break;
        case 5:
          this.renderValue = 'Pending';
          break;
        case 6:
          this.renderValue = 'Cancelled';
          break;
        case 7:
          this.renderValue = 'Resolved';
          break;
        case 8:
          this.renderValue = 'Closed';
          break;
      }
    }
  }

}
