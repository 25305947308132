import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketService {
    private path = 'ticket';
    private pathcat = 'category';

    constructor(private http: HttpClient) {}

    createTicket(model: any) {
        return this.http.post(`${this.path}`, model);
    }

    editTicket(prop: string, model: any): Observable<any> {
        return this.http.put(`${this.path}/` + prop, model);
    }

    checkallowdelcomment(ticketId: string) {
       return this.http.get(`${this.path}/checkallowdelcomment/` + ticketId);
    }

    // editTicketStatus(prop: string, model: any): Observable<any> {
    //     return this.http.put(`${this.path}/status/` + prop, model);
    // }

    uploadDocs(model: any) {
        return this.http.post(`${this.path}/upload`, model);
    }

    removeDoc(docId: any) {
        const model = {};
        return this.http.post(`${this.path}/attachment/delete/${docId}`, model);
    }

    getTickets(status: number): Observable<any> {
        return this.http.get(`${this.path}/getTickets/` + status);
    }
    getOpenTickets(userId: string): Observable<any> {
        return this.http.get(`${this.path}/getOpenTickets/` + userId);
    }
    getAllOpenTickets(): Observable<any> {
        return this.http.get(`${this.path}/getOpenTickets`);
    }
    getClosedTickets(userId: string): Observable<any> {
        return this.http.get(`${this.path}/getClosedTickets/` + userId);
    }
    getAllClosedTickets(): Observable<any> {
        return this.http.get(`${this.path}/getClosedTickets`);
    }

    getSlaReport(start: string, end: string) {
        let httpParams = new HttpParams();
        httpParams = httpParams.append('start', start);
        httpParams = httpParams.append('end', end);
        return this.http.get(`report/sla-quaterly`, {params: httpParams});
    }

    getTicket(ticketId: string): Observable<any> {
        return this.http.get(`${this.path}/` + ticketId);
    }

    getStatuses(): Observable<any> {
        return this.http.get(`${this.path}/statuses`);
    }

    getPriorities(): Observable<any> {
        return this.http.get(`${this.pathcat}/listpriority`);
    }

    getResolution(): Observable<any> {
        return this.http.get(`${this.path}/resolution`);
    }    
    
    addComment(model: any): Observable<any> {
        return this.http.post(`${this.path}/comment`, model);
    }    
    
    deleteComment(model: any): Observable<any> {
        return this.http.put(`${this.path}/delcomment`, model);
    }

    getFile(attId: string, ticketNumber: string): Observable<any> {
        return this.http.get(`${this.path}/getfile/${attId}/${ticketNumber}` );
    }

    getTypes(): Observable<any> {
        return this.http.get(`${this.path}/types`);
    }

    getBorang(ticketId: string): Observable<HttpEvent<Blob>> {
        return this.http.request(new HttpRequest(
          'GET',
          `${this.path}/borang/${ticketId}`,
          null,
          {
            reportProgress: true,
            responseType: 'blob'
          }));
      }
      getSupervisorAllowedChangeAssignee(createdOn: string): Observable<any> {
        return this.http.get(`${this.path}/calculate/${createdOn}`);
      }

      viewFileContent(ticketId: string): Observable<any> {
        return this.http.get(`${this.path}/borang2/${ticketId}`, {
          reportProgress: true,
          responseType: 'blob'
        }).pipe(map((x) => {
          var res = x;
          let file;
          let type: string | undefined = '';
          if(res) {
            switch(res.type.toLowerCase()) {
              case 'application/octet-stream':
              case 'application/pdf':
                type = 'application/pdf';
                file = res;
                break;
              case 'image/tiff':
              case 'tiff':
                type = res.type;
                file = new Blob([res], {type: res.type});
                break;
              default:
                type = res.type;
                file = res;
                break;
            }
            
          }
    
          return { file: file, type: type };
        }));
      }

}
