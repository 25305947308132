<span *ngIf="rowData.ticketPriority === '0a9eaa3c-10ce-11ef-a12d-bcee7b89cb8a'" style="color:#ff3d71;">{{ renderValue }}</span>
<span *ngIf="rowData.ticketPriority === '02cb8f77-10ce-11ef-a12d-bcee7b89cb8a'" style="color:#fa0">{{ renderValue }}</span>
<span *ngIf="rowData.ticketPriority === 'f5d930b1-10cd-11ef-a12d-bcee7b89cb8a'" style="color:#00d68f;">{{ renderValue }}</span>

<!--

     <button nbButton ghost status="danger" *ngIf="rowData.ticketPriority === 1">
    {{ renderValue }}
</button>
<button nbButton ghost status="warning" *ngIf="rowData.ticketPriority === 2">
    {{ renderValue }}
</button>
<button nbButton ghost status="success" *ngIf="rowData.ticketPriority === 3">
    {{ renderValue }}
</button>
-->