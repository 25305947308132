<nb-layout windowMode>
    <nb-layout-column>
      <!-- <ng-content select="router-outlet">
  
      </ng-content> -->
  
      <nb-card [nbSpinner]="loading" nbSpinnerSize="medium" nbSpinnerStatus="primary">
        <nb-card-header>
  
        </nb-card-header>
        <nb-card-body>
          <div class="row">
            <div class="col-md-12" *ngIf="!isShow">
              <form (ngSubmit)="resetPassword()" [formGroup]="form" aria-labelledby="title">
                <div class="row">
                  <div class="col-lg-12 text-center">
                    <img class="clientlogo" src="assets/images/logo.jpg">
                  </div>
                  
                  <div class="col-lg-12 text-center mt-5">
                    <h6>Reset your password</h6>
                    <label>Enter your user account's verified email address and we will send you a password.</label>
                  </div>
                </div>
                <div class="form-control-group">
                  <label class="label" for="input-email">User Email:</label>
                  <input nbInput formControlName="email" fullWidth name="userid" id="input-userid" placeholder="Email"
                    fieldSize="large" autofocus>
                  <span class="error" *ngIf="submitted && form.get('email').errors?.required">
                    Email is required.
                  </span>
                </div>
    
                <button nbButton fullWidth status="primary" type="submit" size="large">Send</button>
      
              </form>
            </div>
            <div class="col-md-12" *ngIf="isShow">
              Your password is reset and sent to your email. Please check your email to receive new password.
            </div>
            <div class="col-md-12 mt-5 text-right">
              Return <a href='/login'>Login page</a>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </nb-layout-column>
  </nb-layout>