import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable()
export class CustomerService {
    private path = 'customer'

    constructor(private http: HttpClient) {}

    create(model: any): Observable<any> {
        return this.http.post(`${this.path}`, model);
    }

    edit(customerId: string, model: any): Observable<any> {
        return this.http.put(`${this.path}/${customerId}`, model);
    }

    delete(custId: string): Observable<any> {
        return this.http.delete(`${this.path}/${custId}`);
    }

    getCustomers(): Observable<any> {
        return this.http.get(`${this.path}`)
    }
}