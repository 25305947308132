import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class BaseService {
  private coreUrl: string;
  private apiUrl: string;
  private version: string;

  constructor(private http: HttpClient) {
    this.coreUrl = environment.coreUrl;
    this.apiUrl = `${this.coreUrl}/api`;
  }

  public getCoreUrl(): string {
    return this.coreUrl;
  }

  public getApiUrl(): string {
    return this.apiUrl;
  }

  public getVersion(): string {
    return this.version;
  }

  public configHttpParams(criterion: any): HttpParams {
    return criterion
      ? Object.keys(criterion).reduce(
          (httpParams, key) => httpParams.set(key, criterion[key]),
          new HttpParams(),
        )
      : new HttpParams();
  }
}
