import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlProperty } from 'app/common/models/control-property';
import { Meta } from 'app/common/models/meta-data';
import { CustomValidators } from 'app/common/validation/custom-validator';

@Component({
  selector: 'ngx-dynamic-form-template',
  templateUrl: './dynamic-form-template.component.html',
  styleUrls: ['./dynamic-form-template.component.scss'],
})
export class DynamicFormTemplateComponent implements OnInit {
  @Input() controlProperties: ControlProperty[];
  public formFields: FormArray = this.formBuilder.array([]);

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.buildForm();
  }

  private newMetaFromValues(v): Meta {
    const key = this.isString(v) ? v : v['key'];
    const value = this.isString(v) ? v : (v['value'] as string);
    return new Meta(key, value, false, true);
  }

  private isString(input: any) {
    return typeof input === 'string' || input instanceof String;
  }

  private buildForm() {
    if (this.controlProperties) {
      this.controlProperties.forEach(p => {
        if (p.values && typeof(p.values) === 'string') {
          p.values = JSON.parse(p.values).map((value) => this.newMetaFromValues(value));
        } else if (!p.values || !p.values.length) {
          p.values = {};
        }
      });

      this.formFields = this.formBuilder.array(
        this.controlProperties.map((item) => this.builControlItem(item)),
      );
    } else {
      this.formFields = this.formBuilder.array([]);
    }
  }

  private builControlItem(setting: ControlProperty): FormGroup {
    return this.formBuilder.group({
      key: [setting.key],
      value: [setting.value, this.createValidators(setting)],
    });
  }

  private createValidators(property: ControlProperty): Validators[] {
    const customValidators: Validators[] = property.required
      ? [Validators.required]
      : [];
    if (property.regex) {
      customValidators.push(CustomValidators.regex(property.regex));
    }
    return customValidators;
  }

  public getFormControl(key: string): FormControl {
    return this.formFields.controls
      .find((control) => control.value.key === key)
      .get('value') as FormControl;
  }
}
